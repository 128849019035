@font-face {
  font-family: 'Permanent Marker';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/permanentmarker/v16/Fh4uPib9Iyv2ucM6pGQMWimMp004Hao.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrIzc.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjARc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu52xP.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ABc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBhc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBBc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgWxP.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5vAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtvAw.ttf) format('truetype');
}
:root {
  --accent: purple;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}
body {
  scroll-behavior: smooth;
}
h1,
h2,
h3 {
  font-family: 'Permanent Marker', cursive;
  font-weight: 900;
  max-width: 70ch;
}
h1 {
  font-size: 1.5rem;
  line-height: 1rem;
  margin: 0;
  padding: 0;
}
h2 {
  font-size: 3rem;
  margin-bottom: 0;
  margin-top: 1rem;
}
h3 {
  margin-bottom: 0;
}
p {
  max-width: 70ch;
  padding: 1rem 0;
  margin: 0;
}
header sub {
  font-size: 0.7rem;
  font-weight: 400;
}
header nav {
  perspective: 1000px;
}
header h1 a,
header h1 a:visited,
header nav a,
header nav a:visited {
  text-decoration: none;
  color: inherit;
  transition: all 0.2s linear;
  display: inline-block;
}
header nav a:hover,
header nav a:focus {
  text-decoration: none;
  color: #000;
  text-shadow: 0px 0px 4px var(--accent);
  outline: none;
  transform: scale(1.1);
  transform-origin: center;
}
header nav a {
  margin-left: 1rem;
  font-size: 0.8rem;
  font-weight: 600;
}
a[aria-current="page"],
a[aria-current="page"]:visited {
  color: var(--accent);
}
input,
textarea {
  border: 3px solid #000;
  padding: 1rem;
  margin: 1rem 0;
  max-width: 500px;
  width: 100%;
}
button[type="submit"] {
  display: block;
  border: 0;
  background: var(--accent);
  cursor: pointer;
  color: #fff;
  padding: 1rem;
}
.centered {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#root {
  display: flex;
  justify-content: center;
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav ul {
  list-style: none;
  display: flex;
}
.pageContent {
  max-width: 1020px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 400px;
}
/* Timeline below here */
.timeline {
  margin-top: 40px;
}
.timeline ul {
  list-style: none;
  display: grid;
  position: relative;
}
.timeline ul:after {
  content: "";
  width: 100%;
  height: 100px;
  background: #fff;
  position: absolute;
  transition: width 1s;
  top: -50px;
  right: -20px;
}
.timeline.loaded ul:after {
  width: 0%;
}
.timeline li {
  height: 10px;
  display: block;
  background: #373738;
  position: relative;
  cursor: pointer;
  border-top: 3px solid black;
  border-bottom: 3px solid black;
}
.timeline li::before {
  content: "";
  border-radius: 50%;
  display: inline-block;
  height: 25px;
  width: 25px;
  background: #373738;
  position: absolute;
  top: -10px;
  left: -12px;
  border-top: 3px solid black;
  border-bottom: 3px solid black;
}
.timeline li:first-child::before {
  border-left: 3px solid black;
}
.timeline li.active,
.timeline li.active::before {
  background: var(--accent);
  display: flex;
  justify-content: center;
}
.timeline li:last-child {
  justify-content: flex-start;
}
.timeline li:first-child {
  justify-content: flex-end;
}
.timeline li.active span {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 25px solid black;
  margin-top: 30px;
  position: relative;
  opacity: 0;
  transition: opacity 1s 1s;
  z-index: 10000;
}
.timeline li.active span:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 22px solid transparent;
  border-right: 22px solid transparent;
  border-bottom: 22px solid white;
  position: absolute;
  left: -22px;
  top: 4px;
}
.timeline li:last-child::after {
  content: "";
  border-radius: 50%;
  display: inline-block;
  height: 25px;
  width: 25px;
  background: inherit;
  position: absolute;
  top: -8px;
  right: -8px;
  border-top: 3px solid black;
  border-bottom: 3px solid black;
  border-right: 3px solid black;
}
.timeline li .start {
  position: absolute;
  left: -17px;
  top: -38px;
}
.event {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  min-height: 215px;
  opacity: 0;
  transition: opacity 1s 0.5s;
}
.timeline.loaded .event,
.timeline.loaded li.active span {
  opacity: 1;
}
.event .content {
  border: 3px solid #000;
  border-radius: 20px;
  padding: 1rem;
}
@media only screen and (max-width: 480px) {
  .timeline ul {
    padding: 0 15px;
  }
  .timeline li {
    height: 5px;
  }
  .timeline li::before,
  .timeline li:last-child:after {
    height: 15px;
    width: 15px;
    top: -8px;
  }
  .timeline li.active span {
    margin-top: 25px;
  }
  .timeline ul:after {
    height: 75px;
    top: -45px;
    right: 0;
  }
}
/* Projects below here */
.project-page {
  margin-bottom: -200px;
}
.projects-container {
  perspective: 1000px;
  position: relative;
  display: flex;
  z-index: 10;
  height: 260px;
  transform: translateY(75px) rotate(0);
}
.carousel {
  position: relative;
}
.carousel .loading {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  inset: 0;
  display: flex;
  z-index: 10;
}
.carousel .loading + .projects-container .project .top,
.carousel .loading + .projects-container .project .back {
  color: #000;
  backface-visibility: visible;
  height: 70px;
  top: -25px;
}
.projects-container.projects-loading svg,
.projects-container.projects-loading::before,
.projects-container.projects-loading::after,
.projects-container.projects-loading button {
  display: none;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}
.projects-container::before {
  content: "";
  height: 200%;
  width: 200px;
  top: -50%;
  background: linear-gradient(to right, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
  position: absolute;
  left: -100px;
  pointer-events: none;
}
.projects-container::after {
  content: "";
  height: 200%;
  width: 200px;
  top: -50%;
  background: linear-gradient(to left, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
  position: absolute;
  right: -100px;
  pointer-events: none;
}
.projects-container:has( .flipped) button {
  opacity: 0;
}
.projects-container button {
  z-index: 11;
  position: absolute;
  background: transparent;
  border: none;
  font-size: 5rem;
  align-self: center;
  transition: all 1s;
  transform: translateZ(0px);
  z-index: 2;
  cursor: pointer;
}
.projects-container button svg {
  stroke-width: 1rem;
  stroke: #fff;
}
.projects-container button:hover,
.projects-container button:focus {
  transition: transform 0.2s;
  transform: scale(1.2);
  outline: none;
}
.projects-container button:hover svg,
.projects-container button:focus svg {
  stroke: #000;
  fill: #fff;
}
.projects-container button:active svg {
  stroke: var(--accent);
  fill: var(--accent);
}
.projects-container button.prev {
  left: 0;
}
.projects-container button.next {
  right: 0;
}
.projects {
  position: absolute;
  left: 0;
  right: 0;
  transform-style: preserve-3d;
  transition: all 1s;
  display: flex;
  align-items: center;
  transform: translateZ(-10000px) translateY(100px) rotateX(-90deg) rotateZ(0deg);
}
.projects-container.projects-loading {
  animation: rotation infinite 4s linear;
}
.project {
  display: flex;
  align-items: center;
  position: absolute;
  max-width: 418px;
  min-height: 250px;
  margin: auto;
  left: 0;
  right: 0;
  background-color: #fff;
  font-size: 0.8rem;
  border: 3px solid black;
  border-radius: 12px;
  transform-style: preserve-3d;
  transition: all 0.5s;
  cursor: pointer;
}
.project:hover {
  box-shadow: 0px 0px 20px 10px var(--accent);
}
.project.flipped {
  transform: rotateY(180deg);
}
.project .top {
  background-color: #000;
  border-radius: 10px 0 0 10px;
  content: "";
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateY(-10px) translateZ(-10px) translateX(0) rotateX(90deg);
  width: 100%;
  z-index: -1;
  right: 0;
  margin: auto;
}
.project .bottom {
  background-color: #000;
  border-radius: 10px 0 0 10px;
  content: "";
  height: 20px;
  left: 0;
  position: absolute;
  bottom: -12px;
  transform: translateY(0px) translateZ(-10px) translate(0) rotateX(-90deg);
  width: 100%;
  z-index: -1;
  right: 0;
  margin: auto;
}
.project:before {
  background-color: #000;
  border-radius: 10px 0 0 10px;
  content: "";
  height: 102%;
  left: -4px;
  position: absolute;
  top: -2px;
  transform: rotateY(-90deg) translateX(-11px);
  backface-visibility: hidden;
  width: 21px;
  z-index: -1;
}
.project:after {
  background-color: #000;
  border-radius: 0 10px 10px 0;
  content: "";
  right: 9px;
  position: absolute;
  top: -3px;
  transform: rotateY(90deg) translateX(26px);
  transform-origin: 100% 50%;
  backface-visibility: hidden;
  width: 26px;
  height: 102%;
  z-index: -10;
}
.project .front {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 10px;
  height: 100%;
  backface-visibility: hidden;
  overflow: hidden;
  filter: grayscale(100);
}
.project .back {
  background-color: #fff;
  backface-visibility: hidden;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateY(180deg) translateZ(26px);
  outline: 3px solid black;
  filter: contrast(33%) blur(2px) grayscale(100);
  overflow: hidden;
  box-shadow: 0;
}
.project.selected .back {
  transform: rotateY(180deg) rotateZ(180deg) translateZ(26px);
  filter: contrast(100%) blur(0) grayscale(0);
  display: flex;
  justify-content: center;
  align-items: center;
}
.project.selected.flipped .back {
  transition: box-shadow 0.5s linear;
  box-shadow: 0 0 140px 90px #fff;
}
.project.selected .front {
  filter: grayscale(0);
}
.project .back img,
.project .back video {
  max-width: 100%;
  max-height: 100%;
}
.project.selected h3 {
  color: var(--accent);
}
.softwareproject {
  min-width: 540px;
  min-height: 323px;
}
.softwareproject .image {
  position: relative;
  max-width: 190px;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.softwareproject img,
.softwareproject video {
  max-width: 100%;
  height: auto;
}
.softwareproject .image img,
.softwareproject .image video {
  width: 100%;
  height: auto;
}
.softwareproject .text {
  padding: 1rem;
  flex: 1;
  text-align: left;
  padding-left: 1rem;
  background-color: #fff;
  height: 100%;
}
.softwareproject .back {
  background-color: #000;
}
.artwork.portrait {
  max-width: 288px;
  min-height: 382px;
}
.artwork.catbox {
  max-width: 288px;
  min-height: 412px;
}
.artwork.smalldiorama {
  max-width: 287px;
  min-height: 354px;
}
.artwork,
.artwork .front,
.artwork .back {
  background: #000;
  overflow: visible;
}
.artwork .front .image {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.artwork .art-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.artwork .art-container .info {
  position: absolute;
  bottom: -72px;
  text-align: center;
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s linear;
}
.artwork .art-container .info p {
  margin-top: 0;
  padding-top: 0.5rem;
}
.artwork.selected .art-container .info {
  opacity: 1;
}
.artwork.square {
  max-width: 300px;
  min-height: 300px;
}
.artwork.skate {
  max-width: 360px;
  min-height: 100px;
  border-radius: 50px;
}
.artwork.skate .front,
.artwork.skate .back {
  border-radius: 50px;
  overflow: hidden;
}
.artwork.skate .top,
.artwork.skate .bottom {
  width: 80%;
}
.artwork.skate::before {
  left: 28px;
}
.artwork.skate::after {
  right: 38px;
}
.artwork.long {
  max-width: 322px;
  min-height: 100px;
}
.artwork.supertall {
  max-width: 68px;
  min-height: 450px;
}
.artwork img,
.artwork video {
  max-width: 100%;
}
@media only screen and (max-width: 480px) {
  .projects-container .projects {
    scale: 0.69;
    top: 50px;
  }
  .projects-container .softwareproject {
    min-width: 375px;
    min-height: 585px;
    font-size: 1rem;
  }
  .projects-container .project .front {
    flex-direction: column;
  }
  .projects-container .project .image {
    max-width: 100%;
    height: auto;
    align-items: flex-start;
  }
  .projects-container button {
    font-size: 3rem;
  }
  .artwork.portrait {
    max-width: 288px;
    min-height: 382px;
  }
  .artwork.square {
    max-width: 300px;
    min-height: 300px;
  }
  .artwork.skate {
    max-width: 360px;
    min-height: 100px;
  }
  .artwork.long {
    max-width: 322px;
    min-height: 100px;
  }
  .artwork.supertall {
    max-width: 68px;
    min-height: 450px;
  }
}
/* fall menu thing below here */
.fallDown {
  perspective: 1000px;
}
.fallDown .fallItem {
  transform-origin: top center;
  backface-visibility: hidden;
  transform: rotateX(0deg);
  transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.fallDown.loading .fallItem {
  transform: rotateX(-180deg);
}
.contact {
  margin-bottom: -200px;
  z-index: 10;
}
.contact input:focus,
.contact textarea:focus,
.contact button:focus {
  border-color: var(--accent);
  box-shadow: 0px 0px 10px 5px var(--accent);
}
.resume {
  margin-bottom: -50px;
  z-index: 10;
}
/* Game stuff below here */
.controls {
  display: block;
  height: 112px;
  width: 100%;
  margin-top: -30px;
  position: relative;
}
#content {
  overflow: hidden;
  padding: 0px;
  margin: 0px;
  width: 100%;
  max-height: 500px;
  position: relative;
  max-width: 1250px;
}
.game {
  overflow: hidden;
  position: relative;
  max-height: 500px;
  display: flex;
  justify-content: center;
}
canvas {
  margin: 0px;
}
#content::before {
  content: "";
  height: 100%;
  width: 300px;
  background: linear-gradient(to right, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
  position: absolute;
  left: -100px;
  pointer-events: none;
}
#content::after {
  content: "";
  height: 100%;
  width: 300px;
  background: linear-gradient(to left, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
  position: absolute;
  right: -100px;
  pointer-events: none;
}
canvas.paused {
  filter: blur(2px) grayscale(100%);
}
.circles {
  display: block;
  height: 112px;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.circles:before {
  content: "";
  display: block;
  width: 233px;
  height: 265px;
  border: 5px solid;
  border-radius: 50%;
  position: absolute;
  left: -66px;
  background: white;
}
.circles:after {
  content: "";
  display: block;
  width: 236px;
  height: 200px;
  border: 5px solid;
  border-radius: 50%;
  position: absolute;
  right: -59px;
  background: white;
  top: 13px;
}
.d-pad {
  display: block;
  width: 110px;
  height: 110px;
  position: absolute;
  top: 30px;
  left: 18px;
  text-align: center;
  transform: rotate(-15deg);
}
.d-pad > span {
  background: #373738;
}
.up,
.left,
.center,
.right,
.down {
  display: block;
  width: 26%;
  height: 26%;
  background: white;
  margin: auto;
}
.up,
.left,
.right,
.down {
  border: 3px solid black;
}
.up:active,
.left:active,
.right:active,
.down:active {
  background: grey;
}
.left,
.center,
.right {
  display: inline-block;
  margin: 0px -3px;
}
.up,
.down {
  margin: -8px auto;
}
.right {
  border-left: none;
}
.left {
  border-right: none;
}
.up {
  border-bottom: none;
}
.down {
  border-top: none;
}
.actions {
  position: absolute;
  right: 11px;
  top: 29px;
  transform: rotate(0deg);
}
.jump,
.shoot {
  display: inline-block;
  border: 3px solid black;
  width: 53px;
  height: 53px;
  border-radius: 50%;
}
.jump {
  background: #adbef7;
}
.jump:active,
.shoot:active {
  background: grey;
}
.shoot {
  width: 75px;
  height: 75px;
  background: #dcadf7;
}
.hide {
  display: none;
}
@media screen and (max-width: 900px) {
  .projects-container::before,
  .projects-container::after,
  #content::before,
  #content::after {
    display: none;
  }
}
